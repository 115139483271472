import React from "react";

export default function About() {
  return (
    <section>
      <h2>About Riot API...</h2>
      <h3>self taught web developer</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, at
        id cum iusto eveniet obcaecati impedit facilis minus porro omnis illum
        explicabo voluptatum repellat vitae fuga debitis velit tempore tempora!
      </p>
      <h3>Content creator and course teacher</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, at
        id cum iusto eveniet obcaecati impedit facilis minus porro omnis illum
        explicabo voluptatum repellat vitae fuga debitis velit tempore tempora!
      </p>
    </section>
  );
}